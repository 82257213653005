import { render, staticRenderFns } from "./Patterns.vue?vue&type=template&id=66b2b909&scoped=true&"
import script from "./Patterns.vue?vue&type=script&lang=js&"
export * from "./Patterns.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b2b909",
  null
  
)

export default component.exports