<template>
  <div id="patterns-container">
    <h2><i class="fad fa-fw fa-head-side-brain"></i> Behaviour Science</h2>
    <p class="lead">
      A database of more or less proven Behaviour Patterns which you can use to generate
      <router-link to="/ideas">Ideas</router-link>, connect <router-link to="/practices">Best Practices</router-link>
      and <router-link to="/experiments">Experiments</router-link>.
    </p>

    <ComingSoonBanner></ComingSoonBanner>

    <!--<div class="navbar sticky-top navbar-expand-lg navbar-light bg-light rounded mt-4 mb-4">
      <span class="navbar-brand mb-0 h1">Filter</span>
      <div class="navbar-collapse">
        <ul class="navbar-nav d-flex justify-content-around">
          <li v-for="(filter, fIndex) in Object.keys(patternFilters)" class="nav-item dropdown" :key="fIndex">
            <a @click="togglePatternFilterDropdown(filter)" class="nav-link dropdown-toggle" style="text-transform: capitalize">{{ filter }}</a>
            <div class="dropdown-menu" :class="filtersOpen.indexOf(filter) >= 0 ? 'show' : ''">
              <a @click="togglePatternFilter(filter, 'none')" class="dropdown-item text-muted" href="javascript:void(0)">Alle entfernen</a>
              <a @click="togglePatternFilter(filter, 'all')" class="dropdown-item text-muted" href="javascript:void(0)">Alle hinzufügen</a>
              <div class="dropdown-divider"></div>
              <div class="scroll-container">
                <div v-for="(option, oIndex) in patternFilters[filter]" @click="togglePatternFilter(filter, option)" class="dropdown-item" :key="oIndex">
                  <a href="javascript:void(0)">{{ option }}</a>
                  <i v-if="userPatternFilters[filter].indexOf(option) >= 0" class="far fa-check text-success float-right mt-1"></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="row mt-4">
      <div v-for="(pattern, index) in patternsView" :id="'pattern-' + index" class="pattern col-sm-6 mb-4" :key="index">
        <div class="card h-100">

          <div class="card-body cursor-pointer" @click="togglePatternCard(index)">
            <div class="row align-items-center">
              <div class="col-auto text-center"><i class="fad fa-3x ml-2" :class="'fa-' + pattern.icon"></i></div>
              <div class="col">
                <h3 class="card-title mb-2">{{ pattern.title }}</h3>
                <p class="lead mb-0">{{ pattern.subtitle }}</p>
              </div>
              <div class="col-auto text-right">
                <p class="h6 mb-0 text-muted">
                  <span v-if="pattern.experiments.length > 0">{{ pattern.experiments.length }} <i class="fad fa-microscope"></i> </span>
                  <span v-if="pattern.practices.length > 0">{{ pattern.practices.length }} <i class="fad fa-box-full"></i> </span>
                  <span v-if="pattern.ideas.length > 0">{{ pattern.ideas.length }} <i class="fad fa-database"></i> </span>
                </p>
                <ul class="list-unstyled my-0">
                  <li v-if="pattern.limbic.find(l => l === 'Dominanz')" class="badge badge-pill badge-danger">&nbsp;</li>
                  <li v-if="pattern.limbic.find(l => l === 'Kontrolle/Disziplin')" class="badge badge-pill badge-primary">&nbsp;</li>
                  <li v-if="pattern.limbic.find(l => l === 'Stimulanz')" class="badge badge-pill badge-warning">&nbsp;</li>
                  <li v-if="pattern.limbic.find(l => l === 'Balance')" class="badge badge-pill badge-success">&nbsp;</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="collapse" style="display:none">
            <div class="card-body pt-0">
              <hr class="mt-0">

              <blockquote>{{ pattern.application }}</blockquote>

              <p class="card-text">{{ pattern.description }}</p>

              <p><a v-if="pattern.details" @click="togglePatternDetails(index)" href="javascript:void(0)">more</a></p>
              <p :id="'pattern-' + index + '-details'" class="card-text" style="display:none">{{ pattern.details }}</p>

              <div v-if="pattern.practices.length > 0">
                <h4>Practices:</h4>
                <div class="scroll-container-h border rounded mb-3">
                  <div class="row flex-nowrap align-items-center">
                    <div v-for="(practice, index) in pattern.practices" class="col-3 img-wrapper" :key="index">
                      <img :src="practice.image" alt="" class="img-fluid">
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="pattern.experiments.length > 0">
                <h4>Experiments:</h4>
                <div class="scroll-container-v border rounded">
                  <table class="table table-striped mt-3">
                    <tbody>
                    <tr v-for="(experiment, index) in pattern.experiments" :key="index">
                      <th scope="row">{{ experiment.title }}</th>
                      <td :class="(experiment.significant === 'true' ? '' : 'text-muted')" class="text-right">{{ (experiment.uplift*100).toFixed(2) }} %</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="col-sm-6">
                  <h5>Ideas</h5>
                  <ul class="list-group list-group-sm mx-0 px-0">
                    <li v-for="(idea, index) in pattern.ideas" class="list-group-item" :key="index">
                      {{ idea }}
                    </li>
                  </ul>
                </div>
                <div class="col-sm-6 text-right">
                  <h5>Caveats</h5>
                  <ul class="list-group list-group-sm mx-0 px-0">
                    <li v-for="(caveat, index) in pattern.caveats" class="list-group-item" :key="index">
                      {{ caveat }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
// @ is an alias to /src


import ComingSoonBanner from "@/components/ComingSoonBanner";
export default {
  name: 'Patterns',
  components: {
    ComingSoonBanner
  },
  data() {
    return {
      filtersOpen: [],
      userPatternFilters: {
      }
    }
  },
  computed: {
    patterns() {
      return this.$store.state.patterns.map(pattern => {
        pattern.experiments = this.$store.state.experiments.filter(experiment => experiment.patterns.find(p => p === pattern.title))
        pattern.practices = this.$store.state.practices.filter(practice => practice.patterns.find(p => p === pattern.title))
        pattern.ideas = this.$store.state.ideas.filter(idea => idea.patterns.find(p => p === pattern.title))
        return pattern;
      });
    },
    patternFilters() {
      return this.$store.state.patternFilters;
    },
    filteredPatterns() {
      let view = this.patterns;

      Object.keys(this.patternFilters).forEach(filter => {
        view = view.filter(obj => {

          let included = false;
          if (Array.isArray(obj[filter]) && obj[filter].length > 0) {
            obj[filter].forEach(option => {
              included = this.userPatternFilters[filter].indexOf(option) >= 0;
            });
          } else if (Array.isArray(obj[filter]) && obj[filter].length === 0) {
            included = this.userPatternFilters[filter].length === this.patternFilters[filter].length;
          } else {
            included = this.userPatternFilters[filter].indexOf(obj[filter]) >= 0;
          }
          return included;
        });
      });

      return view;
    },
    patternsView() {
      return this.filteredPatterns;
    },
  },
  methods: {
    togglePatternCard(id) {
      // $('#pattern-' + id + ' .collapse').slideToggle();
      let display = document.querySelector('#pattern-' + id + ' .collapse').style.display;
      display = display === 'none' ? 'block' : 'none';
      document.querySelector('#pattern-' + id + ' .collapse').style.display = display;
      document.querySelector('#pattern-' + id).classList.toggle("col-sm-12");
      document.querySelector('#pattern-' + id).classList.toggle("col-sm-6");
    },
    togglePatternDetails(index) {
      // $('#pattern-' + index + '-details').slideToggle();
      let display = document.querySelector('#pattern-' + index + '-details').style.display;
      display = display === 'none' ? 'block' : 'none';
      document.querySelector('#pattern-' + index + '-details').style.display = display;
    },
    togglePatternFilterDropdown(filter) {
      var open = this.filtersOpen;
      if (open.indexOf(filter) >= 0) {
        this.filtersOpen.splice(open.indexOf(filter), 1);
      } else {
        this.filtersOpen.splice(open.indexOf(filter), 1);
        this.filtersOpen.push(filter);
      }
    },
    togglePatternFilter(filter, option) {
      if (option === 'none') {
        this.userPatternFilters[filter].splice(0,this.userPatternFilters[filter].length);
      } else if (option === 'all') {
        this.userPatternFilters[filter].splice(0,this.userPatternFilters[filter].length);
        this.userPatternFilters[filter].splice(0,0, ...this.patternFilters[filter]);
      } else {
        var options = this.userPatternFilters[filter];
        if (options.indexOf(option) >= 0) {
          this.userPatternFilters[filter].splice(options.indexOf(option), 1);
        } else {
          this.userPatternFilters[filter].push(option);
        }
      }
    },
  },
  beforeMount() {
    this.userPatternFilters = Object.assign({}, this.patternFilters);
  }
}
</script>

<style scoped lang="scss">

</style>